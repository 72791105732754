import { parseDate } from "@internationalized/date";
import cn from "classnames";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, useEffect, useRef, useState } from "react";

import Button from "../../../../../atoms/button/Button";
import Heading from "../../../../../atoms/heading/Heading";
import PageStepAnimation from "../../../../../atoms/page-step-animation/PageStepAnimation";
import TrackableLink from "../../../../../atoms/trackable-link/TrackableLink";
import {
  useGlProductBenefits,
  useHealthProductBenefits,
  usePreventionProductBenefits,
} from "../../../../../func-core/core/application/hooks";
import { useQuotesPage } from "../../../../../func-core/core/application/hooks/useQuotesPage";
import { IconNameVariantsName } from "../../../../../func-core/core/ui/atoms/icon/Icon";
import GlProductSelector from "../../../../../func-core/core/ui/organisms/gl-product-selector/GlProductSelector";
import HealthProductSelector from "../../../../../func-core/core/ui/organisms/health-product-selector/HealthProductSelector";
import QuotesInfoCard from "../../../../../func-core/core/ui/organisms/quotes-info-card/QuotesInfoCard";
import CheckList from "../../../../../molecules/check-list/CheckList";
import { DealEventPropertiesDTO } from "../../../../../organisms/deal-event-properties/domain/deal-event-properties";
import {
  useGetDealEventProperties,
  useSetDealEventProperties,
} from "../../../../../organisms/deal-event-properties/use-cases/deal-event-properties-use-cases";
import { Result } from "../../../../../organisms/result/domain/result";
import {
  useResult,
  useSetResultState,
} from "../../../../../organisms/result/use-cases/result-use-cases";
import { DiscountCampaigns } from "../../../../../settings/discounts";
import { isPetYoungerThanMinAge, PetSpecies } from "../../../../../settings/pet";
import { Events, track } from "../../../../../utils/analytics";
import { getCurrentURLSearchParams } from "../../../../../utils/browser-features";
import { parseToCalendarDate } from "../../../../../utils/date";
import { handleValidatePromotionCodeErrors } from "../../../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../../../utils/hooks/useTrackPageViewed";
import {
  isPolicyStartDateChangeAvailable,
  shouldAllowBecasDiscount,
  shouldAllowFakeChipNumber,
  shouldNavigateToSmallPrintPages,
  shouldShowGlAddon,
} from "../../../../../utils/locale-configuration-utils";
import { rollbar, RollbarLogArgument } from "../../../../../utils/rollbar";
import { useLead } from "../../../../leads-funnel/application/lead-use-cases";
import { Lead } from "../../../../leads-funnel/domain/lead";
import {
  useHealthProductConditions,
  useHealthProductFeatures,
} from "../../../application/product-use-cases";
import {
  usePromotionCode,
  useValidatePromotionCode,
} from "../../../application/promotion-code-use-cases";
import { usePersistCart, useShoppingCart } from "../../../application/shopping-cart-use-cases";
import BarkibuCardBanner from "../../organisms/barkibu-card-banner/BarkibuCardBanner";
import HealthProductDisplay from "../../organisms/health-product-display/HealthProductDisplay";
import PaymentIntervalMonthsSelector from "../../organisms/payment-interval-months-selector/PaymentIntervalMonthsSelector";
import PolicyStartDateSelector from "../../organisms/policy-start-date-selector/PolicyStartDateSelector";
import * as styles from "./Quotes.module.scss";

interface QuotesProps {
  conditionsList: Record<string, string[] | string>[];
  productFeaturesList: { locale: string; entryTitle: string; features: string[] }[];
}

const Quotes = ({ conditionsList, productFeaturesList }: QuotesProps): ReactElement => {
  const currentURLSearchParams = getCurrentURLSearchParams();
  const noEditable = currentURLSearchParams?.get("no_editable");
  const utmCampaign = currentURLSearchParams?.get("utm_campaign");
  const validatePromotionCode = useValidatePromotionCode();
  const promotionCode = usePromotionCode();
  const hasBecasDiscount =
    (utmCampaign && utmCampaign in DiscountCampaigns) ||
    (promotionCode && promotionCode.campaignName in DiscountCampaigns);
  const result = useResult() as Result;
  const setResult = useSetResultState();
  const lead: Lead = useLead();
  const { t } = useTranslation();
  const cart = useShoppingCart();
  const persistCart = usePersistCart();
  const getDealEventProperties = useGetDealEventProperties();
  const setDealEventProperties = useSetDealEventProperties();
  const shouldShowAlternateBenefits =
    shouldShowGlAddon(result.country) && result.pet_species === PetSpecies.dog;

  const healthBenefitsList = useHealthProductConditions(
    conditionsList,
    result.country,
    result.pet_species,
    shouldShowAlternateBenefits ? "benefits-alternate" : "benefits"
  );

  const healthProductsFeatures = useHealthProductFeatures(productFeaturesList, result?.country);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [hasHealthProductError, setHasHealthProductError] = useState<boolean>(false);
  const [hasPaymentIntervalMonthsError, setHasPaymentIntervalMonthsError] =
    useState<boolean>(false);
  const [hasInvalidPolicyStartDate, setHasInvalidPolicyStartDate] = useState<boolean>(false);
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const hasFakeChipNumber: boolean = shouldAllowFakeChipNumber(result?.country);
  const isDisabledSubmit =
    isFirstRender ||
    hasHealthProductError ||
    hasPaymentIntervalMonthsError ||
    hasInvalidPolicyStartDate;
  const initialPolicyStartDateRef = useRef(result.policy_start_date);
  const { benefits: healthBenefits } = useHealthProductBenefits(result.country);
  const { benefits: preventionBenefits } = usePreventionProductBenefits(
    result.country,
    result.pet_species
  );
  const { benefits: glBenefits } = useGlProductBenefits(result.country);
  const { shouldShowHealthProductDisplay } = useQuotesPage();

  const nextUrl = (): string => {
    if (noEditable) {
      return `/results/${result?.uuid}/details/?no_editable=1`;
    }

    if (hasFakeChipNumber) {
      return `/results/${result?.uuid}/pet_parent/`;
    }

    return `/results/${result?.uuid}/${
      shouldNavigateToSmallPrintPages(result?.country) ? "small_print/" : "has_chip/"
    }`;
  };

  const handleChangePolicyStartDate = (value: string): void => {
    setResult({ policy_start_date: value });
  };

  const handleSubmit = async (): Promise<void> => {
    const properties = {
      eventSender: "Quotes Page CTA",
      ...(isDisabledSubmit && {
        buttonDisabled: isDisabledSubmit,
        paymentIntervalMonthsUnselected: !cart.paymentIntervalMonths,
        healthProductUnselected: cart.isNeededSelectHealthProduct,
      }),
    };

    track(Events.CLICKED_CTA, properties);

    if (isDisabledSubmit) {
      handleValidationErrors();

      return;
    }

    try {
      setIsLoading(true);

      await persistCart();

      if (getDealEventProperties) {
        try {
          const dealEventProperties: DealEventPropertiesDTO = await getDealEventProperties();
          setDealEventProperties(dealEventProperties);
        } catch (err: any) {
          if (err instanceof Error) {
            setHasSubmitError(true);
            rollbar.error(err.message, err, {
              context: "getDealEventProperties/index",
            });
          }
        }
      }

      await navigate(nextUrl());
    } catch (err: any) {
      rollbar.warn("Error on submit", err as RollbarLogArgument, {
        context: "results/index",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidationErrors = () => {
    setIsFirstRender(false);
    setHasPaymentIntervalMonthsError(!cart.paymentIntervalMonths);
    setHasHealthProductError(cart.isNeededSelectHealthProduct);
  };

  useEffect((): void => {
    const setBecasDiscount = async (): Promise<void> => {
      if (!utmCampaign) {
        return;
      }

      try {
        await validatePromotionCode(
          result.pet_parent_kb_key,
          result.country,
          DiscountCampaigns[utmCampaign],
          utmCampaign
        );
      } catch (error) {
        handleValidatePromotionCodeErrors(error, "PromotionCodeForm");
      }
    };

    if (result && shouldAllowBecasDiscount(result?.country) && hasBecasDiscount) {
      void setBecasDiscount();
    }
  }, []);

  useEffect(() => {
    if (cart.paymentIntervalMonths && !cart.isNeededSelectHealthProduct) {
      setIsFirstRender(false);
    }
  }, [cart.isNeededSelectHealthProduct, cart.paymentIntervalMonths, result.country]);

  useTrackPageViewed(Events.QUOTES_DISPLAYED_BROWSER, {
    isPetYoungerThanMinAge: isPetYoungerThanMinAge(result.pet_birth_date),
    ...(lead.howKnowUs && { howKnowUs: lead.howKnowUs }),
  });

  if (result && result.uncoveredReason) {
    void navigate(`/results/${result.uuid}/uncovered/`);

    return <></>;
  }

  return (
    <>
      {result && (
        <>
          <PageStepAnimation>
            {shouldShowHealthProductDisplay ? (
              <HealthProductDisplay
                benefits={healthBenefitsList}
                features={healthProductsFeatures}
                isDisabled={isLoading}
                hasHealthProductError={hasHealthProductError}
                setHasHealthProductError={setHasHealthProductError}
                {...(hasBecasDiscount && { hasBecasDiscount: true })}
              />
            ) : (
              <section className={styles.productsContainer}>
                <Heading level={1} adoptionClassName={styles.title}>
                  {t("pg_quotes.health_product_selector.title", { petName: result?.pet_name })}
                </Heading>
                <HealthProductSelector hasBecasDiscount />
                <QuotesInfoCard
                  visibilityTrackerId={`health-product-benefits-card`}
                  headingTitle={t("product_benefits.accidents_or_illness.title")}
                  adoptionClassName={styles.quotesInfoCard}
                  icon={IconNameVariantsName.bag}
                >
                  <CheckList
                    id={`health-product-benefits`}
                    items={healthBenefits}
                    adoptionClassName={styles.benefitsCheckList}
                  />
                </QuotesInfoCard>
                <QuotesInfoCard
                  visibilityTrackerId={`prevention-product-benefits-card`}
                  headingTitle={t("product_benefits.prevention_care.title")}
                  adoptionClassName={styles.quotesInfoCard}
                  icon={IconNameVariantsName.syringe}
                >
                  <CheckList
                    id={`health-product-benefits`}
                    items={preventionBenefits}
                    adoptionClassName={styles.benefitsCheckList}
                  />
                </QuotesInfoCard>
                {result.hasGlQuote && <GlProductSelector benefits={glBenefits} />}
                <div className={styles.barkibuCardBannerWrapper}>
                  <BarkibuCardBanner adoptionClassName={styles.barkibuCardBanner} />
                </div>
              </section>
            )}

            {(isPetYoungerThanMinAge(result.pet_birth_date) ||
              isPolicyStartDateChangeAvailable(result.country)) && (
              <PolicyStartDateSelector
                initialValue={parseDate(result?.policy_start_date)}
                updateValue={handleChangePolicyStartDate}
                hasValidationError={hasInvalidPolicyStartDate}
                setHasValidationError={setHasInvalidPolicyStartDate}
                {...(isPolicyStartDateChangeAvailable(result.country) && {
                  customMinValue: parseToCalendarDate(initialPolicyStartDateRef.current),
                })}
                isDisabled={!isPolicyStartDateChangeAvailable(result.country)}
              />
            )}
            {!hasBecasDiscount && (
              <PaymentIntervalMonthsSelector
                isDisabled={isLoading}
                hasPaymentIntervalMonthsError={hasPaymentIntervalMonthsError}
                setHasPaymentIntervalMonthsError={setHasPaymentIntervalMonthsError}
              />
            )}
            <div className={styles.submitButtonWrapper}>
              {hasSubmitError && <p className={styles.errorMessage}>{t("common.error_message")}</p>}

              <Button
                type="submit"
                isLoading={isLoading}
                onClick={handleSubmit}
                aria-disabled={cart.selectedProductsTotalPrice === 0 || isDisabledSubmit}
                adoptionClassName={cn(styles.submitButton, {
                  [styles.isDisabledSubmit]: isDisabledSubmit,
                })}
              >
                {t("common.cta.continue")}
              </Button>
              <div className={styles.downloadConditionsMessage}>
                <p>
                  {t("pg_quotes.conditions.text")}{" "}
                  <TrackableLink
                    to={t("common.policy_example.source")}
                    eventSender={"Sample policy link"}
                    openInNewWindow
                  >
                    {t("pg_quotes.conditions.link.text")}
                  </TrackableLink>{" "}
                  {result.hasGlQuote && (
                    <>
                      {t("pg_quotes.gl_policy_example.text")}{" "}
                      <TrackableLink
                        to={t("common.gl_policy_example.source")}
                        eventSender={"GL Sample policy link"}
                        openInNewWindow
                      >
                        {t("pg_quotes.gl_policy_example.link.text")}
                      </TrackableLink>
                    </>
                  )}
                </p>
              </div>
            </div>
          </PageStepAnimation>
        </>
      )}
    </>
  );
};

export const query = graphql`
  fragment ConditionsFragment on ContentfulConditionsList {
    node_locale
    type
    species
    conditionsItems {
      items
      subtitle
      title
      type
    }
  }
`;

export default Quotes;

import cn from "classnames";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement, useState } from "react";

import PageStepAnimation from "../../../../../atoms/page-step-animation/PageStepAnimation";
import { useCountry } from "../../../../../func-core/core/application/hooks/useCountry";
import { Result } from "../../../../../organisms/result/domain/result";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { isPetYoungerThanMinAge } from "../../../../../settings/pet";
import { Events } from "../../../../../utils/analytics";
import useTrackPageViewed from "../../../../../utils/hooks/useTrackPageViewed";
import useVisibilityTracker from "../../../../../utils/hooks/useVisibilityTracker";
import Barkibu80 from "../../images/barkibu-80.svg";
import HealthProductCard from "../../molecules/health-product-card/HealthProductCard";
import * as styles from "./PreSalesQuotes.module.scss";

const PreSalesQuotes = (): ReactElement => {
  const { ref } = useVisibilityTracker("health-product-selector", {
    threshold: 0.5,
  });
  const result = useResult() as Result;
  const { t, i18n } = useTranslation();
  const { country } = useCountry();
  const [cardsExpanded, setCardsExpanded] = useState(true);

  useTrackPageViewed(Events.QUOTES_DISPLAYED_BROWSER, {
    isPetYoungerThanMinAge: isPetYoungerThanMinAge(result.pet_birth_date),
  });

  const translations = i18n.getResourceBundle(country?.language.code, "translation") as Record<
    string,
    any
  >;

  if (!translations) return <></>;

  const featuresList = Object.entries(translations)
    .filter(([key]) => key.startsWith("pg_quotes.health_product_card.product_features.item_"))
    .reduce((acc, [key, value]) => {
      const itemNumber = key.match(/item_(\d+)/)?.[1];

      if (itemNumber && key.endsWith(".title")) {
        const index = parseInt(itemNumber, 10) - 1;
        acc[index] = value;
      }

      return acc;
    }, [] as Array<string>);

  const getBenefitsList = () => {
    const petType = result.pet_species;
    const benefitsPrefix = `pg_quotes.health_product_card.product_benefits.${petType}`;
    const categories = ["accidents_or_illness", "prevention_care"];

    const benefits = categories
      .map((category) => {
        const titleKey = `${benefitsPrefix}.${category}.title`;
        const title = translations[titleKey] || category;

        const items = Object.entries(translations)
          .filter(
            ([key]) =>
              key.startsWith(`${benefitsPrefix}.${category}.item_`) && key.endsWith(".title")
          )
          .reduce((acc, [key, value]) => {
            const itemNumber = key.match(/item_(\d+)/)?.[1];

            if (itemNumber) {
              const index = parseInt(itemNumber, 10) - 1;
              acc[index] = value;
            }

            return acc;
          }, [] as Array<string>);

        return {
          title,
          items,
        };
      })
      .filter((benefit) => benefit.items.length > 0);

    return benefits;
  };

  if (result && result.uncoveredReason) {
    void navigate(`/results/${result.uuid}/uncovered/`);

    return <></>;
  }
  const healthProduct = {
    image: <Barkibu80 />,
    name: t("product.health.name"),
    features: featuresList,
    benefits: getBenefitsList(),
  };

  return (
    <>
      {result && (
        <>
          <PageStepAnimation>
            <section ref={ref} className={cn(styles.healthProductSelector)}>
              <HealthProductCard
                healthProduct={healthProduct}
                isDisabled={true}
                isExpanded={cardsExpanded}
                handleExpanded={() => setCardsExpanded(!cardsExpanded)}
              />
            </section>
          </PageStepAnimation>
        </>
      )}
    </>
  );
};

export default PreSalesQuotes;

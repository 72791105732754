import { FC } from "react";

import { IconBaseProps, IconNameVariantsName } from "../Icon";
import BagIcon from "./BagIcon";
import LegalIcon from "./LegalIcon";
import SmilingHeartIcon from "./SmilingHeartIcon";
import SyringeIcon from "./SyringeIcon";

const IconComponents: Record<IconNameVariantsName, FC<IconBaseProps>> = {
  smilingHeart: SmilingHeartIcon,
  bag: BagIcon,
  legal: LegalIcon,
  syringe: SyringeIcon,
};

export default IconComponents;

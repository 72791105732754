import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

import {
  useShoppingCart,
  useToggleProduct,
} from "../../../../features/quotes-funnel/application/shopping-cart-use-cases";
import { PaymentIntervalMonths } from "../../../../features/quotes-funnel/settings/payments";
import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { ProductId } from "../../../../settings/products";
import { trackChoice } from "../../../../utils/analytics";
import { formatCurrency } from "../../../../utils/currency";

interface UseGlProductSelector {
  isSelected: boolean;
  formattedPrice: string;
  paymentInterval: string;
  handleToggleProduct: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useGlProductSelector = (): UseGlProductSelector => {
  const { t, i18n } = useTranslation();
  const cart = useShoppingCart();
  const result = useResult();
  const toggleProduct = useToggleProduct();
  const glProduct = cart.getProduct(ProductId.glExtended);
  const isSelected = glProduct?.selected ?? false;
  const isYearly = cart.paymentIntervalMonths === PaymentIntervalMonths.yearly;

  const formattedPrice = formatCurrency(
    (glProduct?.price ?? 0) /
      (isYearly ? PaymentIntervalMonths.monthly : PaymentIntervalMonths.yearly),
    i18n.language,
    result?.currency
  );

  const paymentInterval = t(`quotes.cart.summary.${isYearly ? "yearly" : "monthly"}`);

  const handleToggleProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleProduct(ProductId.glExtended);
    trackChoice(e, { question: "Add GL product" });
  };

  return {
    isSelected,
    formattedPrice,
    paymentInterval,
    handleToggleProduct,
  };
};

import cn from "classnames";
import { ReactElement, ReactNode } from "react";

import * as styles from "./ButtonCardSelector.module.scss";

interface ButtonCardSelectorProps {
  children: ReactNode;
  handleClick: () => void;
  isSelected: boolean;
}

const ButtonCardSelector = ({
  children,
  handleClick,
  isSelected,
}: ButtonCardSelectorProps): ReactElement => {
  return (
    <button
      className={cn(styles.buttonCardSelector, {
        [styles.isSelected]: isSelected,
      })}
      onClick={handleClick}
    >
      {children}
      <div className={styles.iconWrapper}>
        <svg className={cn(styles.icon)} role="img" aria-hidden="true">
          <use href="#icon-thickness-check" />
        </svg>
      </div>
    </button>
  );
};

export default ButtonCardSelector;

// extracted by mini-css-extract-plugin
export var barkibuCardBanner = "Quotes-module--barkibuCardBanner--a9746";
export var barkibuCardBannerWrapper = "Quotes-module--barkibuCardBannerWrapper--d048c";
export var benefitsCheckList = "Quotes-module--benefitsCheckList--e01a6";
export var downloadConditionsMessage = "Quotes-module--downloadConditionsMessage--808d6";
export var errorMessage = "Quotes-module--errorMessage--e64dc";
export var faqs = "Quotes-module--faqs--48aad";
export var glIncluded = "Quotes-module--glIncluded--47b4a";
export var isDisabledSubmit = "Quotes-module--isDisabledSubmit--f55e4";
export var productsContainer = "Quotes-module--productsContainer--99a3d";
export var quote = "Quotes-module--quote--bfd6b";
export var quotesInfoCard = "Quotes-module--quotesInfoCard--c36ea";
export var submitButton = "Quotes-module--submitButton--b8173";
export var submitButtonWrapper = "Quotes-module--submitButtonWrapper--57814";
export var title = "Quotes-module--title--4842a";
export var wrapper = "Quotes-module--wrapper--5b67a";
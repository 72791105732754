import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import ButtonCardSelector from "../../../../../features/quotes-funnel/ui/atoms/button-card-selector/ButtonCardSelector";
import DiscountBadge, {
  DiscountBadgeVariants,
} from "../../../../../features/quotes-funnel/ui/atoms/discount-badge/DiscountBadge";
import CheckList from "../../../../../molecules/check-list/CheckList";
import { useHealthProductSelector } from "../../../application/hooks";
import { IconNameVariantsName } from "../../atoms/icon/Icon";
import QuotesInfoCard from "../../organisms/quotes-info-card/QuotesInfoCard";
import * as styles from "./HealthProductSelector.module.scss";

interface HealthProductSelectorProps {
  hasBecasDiscount?: boolean;
}

const HealthProductSelector = ({ hasBecasDiscount }: HealthProductSelectorProps): ReactElement => {
  const { t } = useTranslation();
  const { healthProducts, features, promotionCode, selectHealthProduct } =
    useHealthProductSelector(hasBecasDiscount);

  return (
    <QuotesInfoCard
      visibilityTrackerId={"health-product-selector"}
      adoptionClassName={styles.quotesInfoCard}
      icon={IconNameVariantsName.smilingHeart}
      headingTitle={t("health_product_selector.card.title")}
    >
      <CheckList
        id={`health-product-features`}
        items={features}
        adoptionClassName={styles.featuresCheckList}
      />
      <ul>
        {healthProducts.map((healthProduct) => (
          <li key={healthProduct.id}>
            <ButtonCardSelector
              handleClick={() => selectHealthProduct(healthProduct.id)}
              isSelected={healthProduct.isSelected}
            >
              <p className={styles.spendingLimit}>{healthProduct.spendingLimit}</p>
              <p className={styles.price}>{healthProduct.price}</p>
              {healthProduct.discountedPrice && promotionCode?.code && (
                <section className={styles.discountedPriceWrapper}>
                  <span className={styles.discountedPrice}>{healthProduct.discountedPrice}</span>
                  <span className={styles.discountedPriceMessage}>
                    {t("quotes.cart.summary.becas_discount.message")}
                  </span>
                  <DiscountBadge
                    discountName={promotionCode?.code}
                    adoptionClassName={styles.discountBadge}
                    variantName={DiscountBadgeVariants.alternate}
                  />
                </section>
              )}
            </ButtonCardSelector>
          </li>
        ))}
      </ul>
    </QuotesInfoCard>
  );
};

export default HealthProductSelector;

import { FC } from "react";

import { IconBaseProps } from "../Icon";

const SmilingHeartIcon: FC<IconBaseProps> = ({
  color = "var(--color2, #fd4d0d)",
  size = 24,
  ...props
}) => (
  <svg viewBox="0 0 19 16" width={size} height={size} {...props}>
    <path
      style={{ fill: color }}
      d="M5.534 2.466c-1.789 0-3.195 1.531-3.040 3.312l0.036 0.417c0.089 1.023 0.576 1.97 1.357 2.638l5.466 4.674 5.498-4.676c0.784-0.667 1.274-1.616 1.363-2.641l0.041-0.477c0.152-1.746-1.226-3.247-2.98-3.247-1.187 0-2.262 0.701-2.739 1.787l-0.238 0.543c-0.149 0.34-0.484 0.561-0.855 0.565s-0.711-0.209-0.868-0.545l-0.275-0.59c-0.501-1.074-1.58-1.76-2.765-1.76zM0.609 5.942c-0.251-2.885 2.026-5.365 4.925-5.365 1.536 0 2.961 0.711 3.886 1.885 0.912-1.172 2.327-1.885 3.857-1.885 2.864 0 5.113 2.45 4.865 5.3l-0.041 0.477c-0.132 1.52-0.858 2.927-2.021 3.916l-6.112 5.199c-0.354 0.301-0.875 0.3-1.228-0.002l-6.080-5.198c-1.158-0.99-1.88-2.394-2.012-3.91l-0.036-0.417z"
    ></path>
    <path
      style={{ fill: color }}
      d="M6.709 7.078c0.423-0.294 1.005-0.192 1.301 0.228 0.731 1.039 2.262 1.088 3.059 0.097l0.117-0.145c0.322-0.401 0.91-0.465 1.313-0.145s0.468 0.905 0.146 1.305l-0.117 0.145c-1.575 1.958-4.601 1.862-6.047-0.193-0.296-0.42-0.193-0.999 0.229-1.293z"
    ></path>
  </svg>
);

export default SmilingHeartIcon;

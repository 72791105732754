import { Result } from "../../../../organisms/result/domain/result";
import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { EXPERIMENT_IDS } from "../../domain/constants";
import { useExperiment } from "./useExperiment";

interface UseQuotesPageReturnType {
  shouldShowHealthProductDisplay: boolean;
}

export const useQuotesPage = (): UseQuotesPageReturnType => {
  const result = useResult() as Result;
  const { isInExperiment, variantId } = useExperiment(EXPERIMENT_IDS.HEALTH_UNLIMITED_VISIBILITY);

  const shouldShowHealthProductDisplay =
    !result.hasUnlimitedHealthQuote || (isInExperiment && variantId === "hidden");

  return { shouldShowHealthProductDisplay };
};

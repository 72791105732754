// extracted by mini-css-extract-plugin
export var baiMessage = "HealthProductCard-module--baiMessage--d8038";
export var barkibuCardBanner = "HealthProductCard-module--barkibuCardBanner--4283a";
export var benefitTitle = "HealthProductCard-module--benefitTitle--4ccab";
export var benefitsWrapper = "HealthProductCard-module--benefitsWrapper--5dd98";
export var bottomButton = "HealthProductCard-module--bottomButton--e7cac";
export var button = "HealthProductCard-module--button--42e3e";
export var discountBadge = "HealthProductCard-module--discountBadge--ec5d3";
export var discountedPrice = "HealthProductCard-module--discountedPrice--39854";
export var discountedPriceMessage = "HealthProductCard-module--discountedPriceMessage--d99ee";
export var discountedPriceWrapper = "HealthProductCard-module--discountedPriceWrapper--092fb";
export var expandableButton = "HealthProductCard-module--expandableButton--fe747";
export var featuresCheckList = "HealthProductCard-module--featuresCheckList--9fb01";
export var featuresNote = "HealthProductCard-module--featuresNote--7b970";
export var footer = "HealthProductCard-module--footer--4e8a5";
export var healthProductCard = "HealthProductCard-module--healthProductCard--3b20c";
export var imageWrapper = "HealthProductCard-module--imageWrapper--75768";
export var isExpanded = "HealthProductCard-module--isExpanded--84c94";
export var moreInfoMessage = "HealthProductCard-module--moreInfoMessage--def50";
export var name = "HealthProductCard-module--name--a9f3b";
export var preSalesMessageWrapper = "HealthProductCard-module--preSalesMessageWrapper--6173a";
export var price = "HealthProductCard-module--price--1d14b";
export var title = "HealthProductCard-module--title--b2563";
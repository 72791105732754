import cn from "classnames";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, ReactNode, useRef } from "react";

import Button from "../../../../../atoms/button/Button";
import Heading from "../../../../../atoms/heading/Heading";
import CheckList from "../../../../../molecules/check-list/CheckList";
import { Result } from "../../../../../organisms/result/domain/result";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { ProductId } from "../../../../../settings/products";
import { Events, track } from "../../../../../utils/analytics";
import DiscountBadge, { DiscountBadgeVariants } from "../../atoms/discount-badge/DiscountBadge";
import BarkibuCardBanner from "../../organisms/barkibu-card-banner/BarkibuCardBanner";
import GlAddon from "../../organisms/gl-addon/GlAddon";
import { HealthProductElement } from "../../organisms/health-product-display/HealthProductDisplay";
import * as styles from "./HealthProductCard.module.scss";

interface HealthProductCardProps {
  healthProduct: HealthProductElement;
  isDisabled: boolean;
  isExpanded: boolean;
  handleExpanded: () => void;
  discountedPrice?: ReactNode;
  appliedPromotionCode?: string;
}

const HealthProductCard = ({
  healthProduct,
  isExpanded,
  handleExpanded,
  discountedPrice,
  appliedPromotionCode,
}: HealthProductCardProps): ReactElement => {
  const result: Result | null = useResult();
  const { image, name, features, price, benefits } = healthProduct;
  const { t } = useTranslation();
  const ref = useRef(null);

  const toggle = (): void => {
    const properties = { block: ProductId.healthBasic };
    const eventName = !isExpanded ? Events.BLOCK_EXPANDED : Events.BLOCK_COLLAPSED;

    handleExpanded();
    track(eventName, properties);
  };

  return (
    <div ref={ref} className={cn(styles.healthProductCard)}>
      <div className={styles.imageWrapper}>{image}</div>
      {!result?.hasHealthQuote && (
        <section className={styles.preSalesMessageWrapper}>
          <Heading level={4} adoptionClassName={styles.title}>
            {t("pg_quotes.pre_sales_message.title")}
          </Heading>
        </section>
      )}
      <Heading level={5} adoptionClassName={styles.name}>
        {name}
      </Heading>
      {result?.hasHealthQuote && <span className={cn(styles.price)}>{price}</span>}
      {discountedPrice && appliedPromotionCode && (
        <section className={styles.discountedPriceWrapper}>
          <div>
            <span className={styles.discountedPrice}>{discountedPrice}</span>
            <span className={styles.discountedPriceMessage}>
              {t("quotes.cart.summary.becas_discount.message")}
            </span>
          </div>
          <DiscountBadge
            discountName={appliedPromotionCode}
            adoptionClassName={styles.discountBadge}
            variantName={DiscountBadgeVariants.alternate}
          />
        </section>
      )}
      <CheckList
        id={`${ProductId.healthBasic}_features`}
        items={features}
        adoptionClassName={styles.featuresCheckList}
      />
      <div
        id={ProductId.healthBasic}
        className={cn(styles.benefitsWrapper, { [styles.isExpanded]: isExpanded })}
      >
        {benefits.map(
          (benefit, index) =>
            benefit.items && (
              <>
                {benefit.type === "gl-addon" ? (
                  <GlAddon benefit={benefit} />
                ) : (
                  <div key={index}>
                    <Heading level={6} adoptionClassName={styles.benefitTitle}>
                      {benefit.title}
                    </Heading>
                    <CheckList
                      key={index}
                      id={`${ProductId.healthBasic}-benefits`}
                      items={benefit.items}
                    />
                  </div>
                )}
              </>
            )
        )}
      </div>
      <Button
        type={"button"}
        className={cn(styles.expandableButton)}
        onClick={toggle}
        aria-controls={ProductId.healthBasic}
        aria-expanded={isExpanded}
      >
        {isExpanded ? t("common.see_less") : t("common.see_more")}
      </Button>
      {result?.hasHealthQuote && <BarkibuCardBanner adoptionClassName={styles.barkibuCardBanner} />}
    </div>
  );
};

export const query = graphql`
  fragment ProductFeaturesFragment on ContentfulProductFeaturesList {
    entryTitle
    features
    locale: node_locale
  }
`;

export default HealthProductCard;

import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactElement } from "react";

import Switch from "../../../../../atoms/switch/Switch";
import CheckList from "../../../../../molecules/check-list/CheckList";
import SectionTitle from "../../../../../molecules/section-title/SectionTitle";
import { useGlProductSelector } from "../../../application/hooks/useGlProductSelector";
import { IconNameVariantsName } from "../../atoms/icon/Icon";
import QuotesInfoCard from "../quotes-info-card/QuotesInfoCard";
import * as styles from "./GlProductSelector.module.scss";

interface GlProductSelectorProps {
  benefits: string[];
}

const GlProductSelector = ({ benefits }: GlProductSelectorProps): ReactElement => {
  const { t } = useTranslation();
  const { isSelected, formattedPrice, paymentInterval, handleToggleProduct } =
    useGlProductSelector();

  return (
    <QuotesInfoCard
      icon={IconNameVariantsName.legal}
      iconSize={28}
      visibilityTrackerId={"gl-product-selector"}
      adoptionClassName={styles.quotesInfoCard}
    >
      <div className={styles.titleContainer}>
        <SectionTitle
          headingLevel={2}
          title={t("gl_product_selector.card.title")}
          adoptionClassName={styles.sectionTitle}
        />
        <p>
          {parse(
            t("gl_addon.price", {
              price: formattedPrice,
            })
          )}
          {paymentInterval}
        </p>
        <Switch
          name={"gl"}
          label={t("common.add")}
          handleChange={(e) => handleToggleProduct(e)}
          isChecked={isSelected}
        />
      </div>
      <CheckList
        id={`gl-product-benefits`}
        items={benefits}
        adoptionClassName={styles.benefitsCheckList}
      />
    </QuotesInfoCard>
  );
};

export default GlProductSelector;

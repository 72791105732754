import { FC } from "react";

import { IconBaseProps } from "../Icon";

const SyringeIcon: FC<IconBaseProps> = ({
  color = "var(--color2, #fd4d0d)",
  size = 24,
  ...props
}) => (
  <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
    <path
      style={{ fill: color }}
      d="M3.675 11.737a.738.738 0 0 0-.523.217L.975 14.131a.738.738 0 0 0 0 1.048.738.738 0 0 0 1.048 0L4.2 13.002a.738.738 0 0 0 0-1.048.74.74 0 0 0-.524-.217zm3.732-6.796a.741.741 0 0 0-.018 1.048l.908.941a.738.738 0 0 0 1.048.019.741.741 0 0 0 .018-1.048l-.908-.941a.737.737 0 0 0-1.048-.018zM5.691 6.69a.738.738 0 0 0 0 1.048l.908.908a.738.738 0 0 0 1.048 0 .738.738 0 0 0 0-1.048l-.908-.908a.738.738 0 0 0-1.048 0zm-1.74 1.707a.738.738 0 0 0 0 1.048l.908.909a.738.738 0 0 0 1.048 0 .738.738 0 0 0 0-1.048l-.908-.908a.738.738 0 0 0-1.048 0zm10.4-5.48h-.005a.737.737 0 0 0-.52.213l-2.303 2.271a.738.738 0 0 0-.006 1.048.738.738 0 0 0 1.047.007l2.303-2.271a.737.737 0 0 0 .008-1.048.738.738 0 0 0-.523-.221zm-1.86-1.85a.734.734 0 0 0-.521.222L9.698 3.592a.737.737 0 0 0 .007 1.047.737.737 0 0 0 1.047-.008l2.272-2.304a.737.737 0 0 0-.008-1.048.737.737 0 0 0-.52-.213h-.006z"
    />
    <path
      style={{ fill: color }}
      d="M7.054.94a.738.738 0 0 0 0 1.048L14.166 9.1a.738.738 0 0 0 1.048 0 .738.738 0 0 0 0-1.048L8.102.94a.738.738 0 0 0-1.048 0zm4.644-.672a.738.738 0 0 0-.523 1.265l3.446 3.445a.738.738 0 0 0 1.048 0 .738.738 0 0 0 0-1.048L12.224.484A.738.738 0 0 0 11.7.267z"
    />
    <path
      style={{ fill: color }}
      d="M9.393 2.573a.738.738 0 0 0-.523.217L1.79 9.902a.74.74 0 0 0 .001 1.047l3.415 3.414a.738.738 0 0 0 1.047.001l7.112-7.08a.737.737 0 0 0 .002-1.048.739.739 0 0 0-1.048-.004l-6.588 6.559-2.368-2.368 6.559-6.59a.738.738 0 0 0-.003-1.047.738.738 0 0 0-.522-.215h-.002z"
    />
  </svg>
);

export default SyringeIcon;

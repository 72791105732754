import { ReactElement, SVGProps } from "react";

import IconComponents from "./icons";

export enum IconNameVariantsName {
  bag = "bag",
  legal = "legal",
  smilingHeart = "smilingHeart",
  syringe = "syringe",
}

export interface IconBaseProps extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

interface IconProps extends IconBaseProps {
  name: IconNameVariantsName;
  "aria-label"?: string;
}

const Icon = ({
  name,
  size = 24,
  color,
  "aria-label": ariaLabel,
  ...props
}: IconProps): ReactElement => {
  const IconComponent = IconComponents[name];

  if (!IconComponent) return <></>;

  return <IconComponent size={size} color={color} role={"img"} aria-label={ariaLabel} {...props} />;
};

export default Icon;

import cn from "classnames";
import { ReactElement, ReactNode } from "react";

import SectionTitle from "../../../../../molecules/section-title/SectionTitle";
import useVisibilityTracker from "../../../../../utils/hooks/useVisibilityTracker";
import Icon, { IconNameVariantsName } from "../../atoms/icon/Icon";
import * as styles from "./QuotesInfoCard.module.scss";

interface QuotesInfoCardProps {
  visibilityTrackerId?: string;
  children: ReactElement | ReactElement[];
  headingLevel?: number;
  headingTitle?: ReactNode;
  adoptionClassName?: string;
  icon: IconNameVariantsName;
  iconSize?: number;
}

const QuotesInfoCard = ({
  visibilityTrackerId = "",
  children,
  headingLevel = 2,
  headingTitle,
  adoptionClassName,
  icon,
  iconSize,
}: QuotesInfoCardProps): ReactElement => {
  const { ref } = useVisibilityTracker(visibilityTrackerId, {
    threshold: 0.5,
  });

  return (
    <div ref={ref} className={cn(styles.quoteInfoCard, adoptionClassName)}>
      <div className={styles.iconWrapper}>
        <Icon name={icon} size={iconSize} />
      </div>
      {headingTitle && (
        <SectionTitle
          headingLevel={headingLevel}
          title={headingTitle}
          adoptionClassName={styles.sectionTitle}
        />
      )}
      {children}
    </div>
  );
};

export default QuotesInfoCard;

import { FC } from "react";

import { IconBaseProps } from "../Icon";

const BagIcon: FC<IconBaseProps> = ({ color = "var(--color2, #fd4d0d)", size = 24, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={size} height={size} {...props}>
    <path
      style={{ fill: color }}
      d="M3.177 5.18c-.797 0-1.444.65-1.444 1.452V12.2c0 .802.646 1.452 1.444 1.452h9.445c.797 0 1.444-.65 1.444-1.452V6.632c0-.802-.646-1.452-1.444-1.452H3.177zM0 6.632a3.185 3.185 0 0 1 3.177-3.194h9.445a3.186 3.186 0 0 1 3.177 3.194V12.2a3.185 3.185 0 0 1-3.177 3.194H3.177A3.186 3.186 0 0 1 0 12.2V6.632z"
    />
    <path
      style={{ fill: color }}
      d="M7.436 1.987c-.722 0-1.388.402-1.739 1.051L4.8 4.696c-.229.423-.748.575-1.159.34s-.56-.768-.331-1.191l.897-1.658A3.679 3.679 0 0 1 7.436.235h.928c1.341 0 2.577.747 3.229 1.952l.897 1.658c.229.423.08.956-.331 1.191s-.931.083-1.159-.34l-.897-1.658a1.98 1.98 0 0 0-1.739-1.051h-.928zm.463 4.44c.472 0 .854.411.854.918v4.141c0 .507-.382.918-.854.918s-.854-.411-.854-.918V7.345c0-.507.382-.918.854-.918z"
    />
    <path
      style={{ fill: color }}
      d="M4.91 9.416c0-.472.411-.854.918-.854h4.141c.507 0 .918.382.918.854s-.411.854-.918.854H5.828c-.507 0-.918-.382-.918-.854z"
    />
  </svg>
);

export default BagIcon;

// extracted by mini-css-extract-plugin
export var baiMessage = "PreSalesQuotes-module--baiMessage--3b2d6";
export var barkibuCardBanner = "PreSalesQuotes-module--barkibuCardBanner--6ceb0";
export var benefitTitle = "PreSalesQuotes-module--benefitTitle--c891b";
export var benefitsWrapper = "PreSalesQuotes-module--benefitsWrapper--82aa8";
export var bottomButton = "PreSalesQuotes-module--bottomButton--10f68";
export var button = "PreSalesQuotes-module--button--6409b";
export var errorMessage = "PreSalesQuotes-module--errorMessage--0f1c5";
export var expandableButton = "PreSalesQuotes-module--expandableButton--b440f";
export var featuresCheckList = "PreSalesQuotes-module--featuresCheckList--84098";
export var featuresNote = "PreSalesQuotes-module--featuresNote--4d632";
export var footer = "PreSalesQuotes-module--footer--0c670";
export var healthProductCard = "PreSalesQuotes-module--healthProductCard--da239";
export var healthProductSelector = "PreSalesQuotes-module--healthProductSelector--66dd3";
export var imageWrapper = "PreSalesQuotes-module--imageWrapper--98d53";
export var isExpanded = "PreSalesQuotes-module--isExpanded--6a174";
export var moreInfoMessage = "PreSalesQuotes-module--moreInfoMessage--6d02b";
export var name = "PreSalesQuotes-module--name--6025f";
export var price = "PreSalesQuotes-module--price--2416c";
export var title = "PreSalesQuotes-module--title--80abf";
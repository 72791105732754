import { FC } from "react";

import { IconBaseProps } from "../Icon";

const LegalIcon: FC<IconBaseProps> = ({
  color = "var(--color2, #fd4d0d)",
  size = 24,
  ...props
}) => (
  <svg fill="none" viewBox="0 0 29 28" width={size} height={size} {...props}>
    <path
      style={{ fill: color }}
      fillRule="evenodd"
      d="m11.787 2.987.842-.858a2.48 2.48 0 0 1 3.409 0l.006.005 4.19 4.191a2.445 2.445 0 0 1 0 3.416l-.004.005-.848.83a2.327 2.327 0 0 1-1.495.712 5.342 5.342 0 0 1-.425.756c-.205.326-.428.64-.668.941 3.421 2.942 7.955 7.069 8.774 7.884a3.91 3.91 0 0 1 1.409 3.015v.02l-.003.021a2.94 2.94 0 0 1-.884 1.719 2.854 2.854 0 0 1-2.01.906h-.017l-.018-.001a4.08 4.08 0 0 1-2.748-1.424c-.814-.817-4.943-5.402-7.869-8.776-.302.24-.617.463-.942.67-.247.159-.504.3-.77.423a2.377 2.377 0 0 1-.695 1.512l-.837.837a2.462 2.462 0 0 1-1.706.698 2.43 2.43 0 0 1-1.71-.7h-.002l-4.19-4.19a2.431 2.431 0 0 1-.699-1.71c.003-.637.253-1.25.698-1.707l.003-.003.837-.837a2.41 2.41 0 0 1 1.49-.673c.125-.277.274-.543.445-.794C6 8.895 6.754 7.99 7.6 7.174a16.374 16.374 0 0 1 2.718-2.267c.246-.168.506-.312.779-.432a2.393 2.393 0 0 1 .69-1.488ZM8.53 17.942l-.053-.024-.052.024-4-4 .021-.046-.023-.056.709-.71 4.034 4.034-.025.086.03.05-.641.642Zm5.034-13.216.01-.036.713-.714.038.014.055-.016 4.067 4.068-.718.718-.06-.017-.043.013-4.062-4.03ZM24.109 24.09a1.88 1.88 0 0 1-.976-.648l-.011-.014-.013-.012c-.61-.61-3.96-4.279-6.858-7.603 3.362 2.927 6.999 6.257 7.595 6.838.319.311.493.574.587.763a1.24 1.24 0 0 1 .1.281.5.5 0 0 1-.154.209l-.028.022-.022.03a.365.365 0 0 1-.22.134ZM12.14 6.706l3.545 3.545a10.376 10.376 0 0 1-2.165 2.752l-.008.008-.009.009a12.93 12.93 0 0 1-2.73 2.196l-3.546-3.53A9.78 9.78 0 0 1 9.39 8.921l.01-.009.01-.01a12.402 12.402 0 0 1 2.728-2.196Z"
      clipRule="evenodd"
    />
  </svg>
);

export default LegalIcon;
